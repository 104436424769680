export const extend = ({ theme }) => ({
    wrapper: {
        paddingTop: '18rem',
    },
    animationSite: {
        max992px: {
            transform: 'scale(0.65)',
        },
        max480px: {
            transform: 'translateX(-15%) translateY(7%) scale(0.65)',
        },
        max400px: {
            transform: 'translateX(-20%) translateY(7%) scale(0.65)',
        },
    },
});

const img = {
    position: 'absolute',
    userSelect: 'none',
    width: '50%',
};

const polygon = {
    position: 'absolute',
};

export const rules = ({ theme }) => ({
    imageLeft: {
        ...img,
        position: 'relative',
        left: '5%',
        marginTop: '5%',
        max1200px: {
            left: 0,
        },
        max480px: {
            left: '10%',
        },
    },

    imageRight: {
        ...img,
        right: '5%',
        top: '12%',
        max1200px: {
            right: '20%',
        },
        max768px: {
            right: '50%',
        },
        max480px: {
            display: 'none',
        },
    },

    polygonTop: {
        ...polygon,
        left: '17%',
        top: '16%',
        width: '20.125rem',
        maxWidth: '100%',
    },

    polygonLeft: {
        ...polygon,
        left: '5%',
        bottom: '16%',
        maxWidth: '14rem',
    },

    polygonRightBottom: {
        position: 'absolute',
        right: '5%',
        bottom: '15%',
        maxWidth: '8rem',
    },
});
