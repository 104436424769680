import React from 'react';
import PropTypes from 'prop-types';
import * as componentStyles from './TopSliderMenuItem.style';
import { FormattedMessage } from 'react-intl';
import { FelaComponent } from 'react-fela';

const TopSliderMenuItem = ({ item, isActive, onChangeHeader }) => (
    <FelaComponent style={componentStyles.headerChangerLink} isActive={isActive}>
        {({ className }) => (
            <button onClick={() => onChangeHeader(item.headerIndex, item.headerType)} className={className}>
                <FormattedMessage id={`home.header.${item.headerType}`} />
            </button>
        )}
    </FelaComponent>
);

TopSliderMenuItem.propTypes = {
    item: PropTypes.shape({
        headerIndex: PropTypes.number,
        headerType: PropTypes.string,
    }).isRequired,
    isActive: PropTypes.bool.isRequired,
    onChangeHeader: PropTypes.func.isRequired,
};

export default TopSliderMenuItem;
