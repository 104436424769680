export const wrapper = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    '& img': {
        display: 'block',
        margin: '3rem auto',
    },
});

const columnDefault = {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    flexWrap: 'wrap',
    alignItems: 'center',
    boxSizing: 'border-box',
    max480px: {
        justifyContent: 'flex-start',
        flex: '0 0 100%', // fill all space
    },
};

export const column = ({ theme }) => ({
    ...columnDefault,
    justifyContent: 'center',
});

export const middleColumn = ({ theme }) => ({
    ...columnDefault,
    justifyContent: 'space-between',
});
