import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import Heading1 from '../../../Heading1';
import Link from '../../../Link';
import star from '../../../../images/star.svg';
import * as componentStyles from './TopSliderContent.style';
import { connect as connectFela } from 'react-fela';

const TopSliderContent = ({ styles, currentItem, className }) => (
    <div className={className}>
        <Heading1 light={true} color="white">
            <FormattedMessage
                id={`home.${currentItem.headerType}.title`}
                values={{
                    strong: chunks => (
                        <>
                            <br />
                            <strong>{chunks}</strong>
                        </>
                    ),
                }}
            />
        </Heading1>
        <Link styleType="button" to={currentItem.to} position="left">
            <FormattedHTMLMessage tagName="span" id={`home.${currentItem.headerType}.link`} />
        </Link>
        <div className={styles.headerMockupsDesc}>
            <FormattedMessage tagName="span" id={`home.${currentItem.headerType}.desc`} />
            {currentItem.ratings > 0 && !currentItem.downloads && (
                <div className={styles.headerMockupsStars}>
                    <span>{currentItem.ratings}</span>
                    {[...Array(parseInt(currentItem.ratings))].map((_, i) => (
                        <img key={i} src={star} alt="" />
                    ))}
                </div>
            )}
            {currentItem.downloads > 0 && !currentItem.ratings && (
                <div className={styles.headerMockupsDownloads}>
                    <span>
                        <strong>{currentItem.downloads}+</strong>{' '}
                        <FormattedMessage id={`home.${currentItem.headerType}.downloads`} />
                    </span>
                </div>
            )}
        </div>
    </div>
);

export default connectFela(componentStyles)(TopSliderContent);
