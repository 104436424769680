export default direction => ({
    position: 'absolute',
    display: 'none',
    min480px: {
        display: 'block',
        [direction]: '10%',
    },
    min768px: {
        [direction]: '13%',
    },
    min992px: {
        [direction]: '12%',
    },
    min1680px: {
        [direction]: '9%',
    },
    zIndex: 5,
});
