export const bgWrapper = ({ theme }) => ({
    backgroundColor: theme.colors.greyLight,
    overflow: 'auto',
});

export const wrapper = ({ theme }) => ({
    maxWidth: '100%',
    margin: '0 auto',
    position: 'relative',
    min1680px: {
        '& .slick-slider': {
            paddingTop: '2rem',
            paddingBottom: '2rem',
        },
    },
    '& .slick-track': {
        height: '52rem',

        max480px: {
            height: '45rem',
        },
        max768px: {
            height: '47rem',
        },
        max1200px: {
            height: '42rem',
        },
        min1920px: {
            height: '40rem',
        },
    },
    '& .slick-dots': {
        bottom: '4rem',
    },
    '& .slick-slide': {
        padding: '0 3.5vw',
        boxSizing: 'border-box',
        opacity: 0.15,
        transition: 'opacity 1s ease',

        '& > div': {
            top: '50%',
            left: '50%',
            position: 'relative',
            transform: 'translate(-50%, -50%)',
        },

        '&.slick-active': {
            opacity: 1,
        },
    },
});

export const personWrapper = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});

export const personAvatar = ({ theme }) => ({
    width: '5rem',
    marginBottom: '1rem',
});

export const personName = ({ theme }) => ({
    fontWeight: 'bold',
    fontSize: '1rem',
    lineHeight: 1.25,
    textAlign: 'center',
});

export const personCompany = ({ theme }) => ({
    fontWeight: 500,
    lineHeight: 1.5,
    textAlign: 'center',
});

export const innerWrapper = ({ theme }) => ({
    color: theme.colors.black,
});

export const text = ({ theme }) => ({
    wordBreak: 'break-word',
    marginBottom: '2.5rem',
    textAlign: 'center',
    display: 'block',

    fontSize: '1.15rem',
    lineHeight: '1.65rem',
    fontWeight: 400,

    min768px: {
        fontSize: '1.5rem',
        lineHeight: '2rem',
    },

    min1200px: {
        fontSize: '2.25rem',
        lineHeight: '3rem',
    },
});
