export const headerMockups = ({ theme }) => ({
    position: 'relative',
});

export const headerMockupsDesc = ({ theme }) => ({
    position: 'absolute',
    bottom: '3rem',
    right: '-5rem',
    max1390px: {
        right: '-2rem',
    },
    max992px: {
        display: 'none',
    },
    color: theme.colors.white,
    textAlign: 'right',
    fontSize: '1.25rem',
    letterSpacing: 0,
});

const mockupDetail = {
    position: 'relative',
    fontWeight: 700,
    fontSize: '1.15rem',
    lineHeight: 2,
    display: 'flex',
    justifyContent: 'flex-end',
    '& span': {
        fontWeight: 'normal',
        paddingRight: '0.25rem',
    },
    max768px: {
        justifyContent: 'flex-start',
    },
};

export const headerMockupsDownloads = ({ theme }) => ({
    ...mockupDetail,
    '& strong': {
        fontWeight: 'bold',
        color: theme.colors.green,
    },
});

export const headerMockupsStars = ({ theme }) => ({
    ...mockupDetail,
    color: theme.colors.gold,
    letterSpacing: '-0.5px',
    '& img': {
        paddingLeft: '0.1rem',
        paddingRight: '0.1rem',
    },
});
