import React from 'react';
import PropTypes from 'prop-types';
import image from '../../../images/polygon.svg';

const PolygonImage = ({ className }) => <img className={className} src={image} alt="" />;
PolygonImage.propTypes = {
    className: PropTypes.string,
};

export default PolygonImage;
