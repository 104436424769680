import PropTypes from 'prop-types';
import { connect as connectFela } from 'react-fela';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import React from 'react';
import Section from '../../Section';

import * as componentStyle from './TeamSection.style';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import PolygonImage from '../../PolygonImage';
import { Parallax } from 'react-scroll-parallax/cjs';
import Link from '../../../../Link';
import { LinkType } from '../../../../Link/Link.style';

const TeamSection = ({ styles, intl }) => {
    const { leftImage, rightImage } = useStaticQuery(
        graphql`
            query {
                leftImage: file(relativePath: { eq: "hp/team/left.png" }) {
                    childImageSharp {
                        fixed(width: 465, height: 515) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                rightImage: file(relativePath: { eq: "hp/team/right.png" }) {
                    childImageSharp {
                        fixed(width: 328, height: 364) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `,
    );
    return (
        <Section
            type="left"
            titleId={`home.team.title`}
            subTitleId={`home.team.subTitle`}
            textId={`home.team.text`}
            extend={componentStyle.extend}
            decoratorComponent={() => (
                <>
                    <PolygonImage className={styles.polygonCenterBottom} />
                </>
            )}
            extraContent={() => (
                <Link
                    styleType={'button'}
                    to={intl.formatMessage({ id: 'home.team.button.href' })}
                    type={LinkType.NATIVE}
                    target={'_blank'}
                >
                    {intl.formatMessage({ id: 'home.team.button.text' })}
                </Link>
            )}
        >
            <Parallax className={styles.imageLeft} y={[-8, 8]}>
                <Img
                    fixed={leftImage.childImageSharp.fixed}
                    alt={intl.formatMessage({ id: 'home.team.images.left.alt' })}
                    fadeIn
                />
            </Parallax>
            <Parallax className={styles.imageRight} y={[-32, 32]}>
                <Img
                    fixed={rightImage.childImageSharp.fixed}
                    alt={intl.formatMessage({ id: 'home.team.images.right.alt' })}
                    fadeIn
                />
            </Parallax>
        </Section>
    );
};

TeamSection.propTypes = {
    styles: PropTypes.shape({
        imageLeft: PropTypes.string,
        imageRight: PropTypes.string,
        polygonRightBottom: PropTypes.string,
    }),
};

export default compose(connectFela(componentStyle.rules), injectIntl)(TeamSection);
