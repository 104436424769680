const animation = opacity => ({
    transitionProperty: 'opacity !important',
    transitionTimingFunction: 'ease !important',
    transitionDuration: '0.6s !important',
    opacity: `${opacity} !important`,
});

export const animationStyle = ({ theme, isContentVisible }) => {
    const opacity = isContentVisible ? 1 : 0;
    return {
        '& .TopSliderContent': {
            ...animation(opacity),
        },
        '& .gatsby-image-wrapper': {
            '&:before': {
                ...animation(opacity),
            },
            '&:after': {
                ...animation(opacity),
            },
            '& picture': {
                ...animation(opacity),
            },
        },
    };
};

export const content = () => ({
    width: '100%',
    margin: '0 auto',
    maxWidth: '100%',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    min768px: {
        paddingLeft: '5.5rem',
        paddingRight: '5.5rem',
    },
    min1490px: {
        maxWidth: '86rem',
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
    },
    min1680px: {
        maxWidth: '96rem',
    },
    min1920px: {
        maxWidth: '110rem',
    },
    '& .Heading1': {
        textAlign: 'left',
        padding: 0,
        marginBottom: '2rem',
        max1200px: {
            min992px: {
                fontSize: '2.5rem',
                lineHeight: '3.5rem',
            },
        },
    },
});
