export const extend = ({ theme }) => ({
    animationSite: {
        display: 'flex',
        alignItems: 'center',
        max1390px: {
            transform: 'scale(0.85)',
        },
        max992px: {
            transform: 'translateY(-10%) scale(0.75)',
        },
    },
});

const img = {
    position: 'relative',
    userSelect: 'none',
};

export const rules = ({ theme }) => ({
    imageLeft: {
        ...img,
        zIndex: 2,
        left: '50%',
        transform: 'translate(-50%, 25%)',
        min992px: {
            left: 'auto',
            transform: 'translate(-15%, 20%)',
        },
        min1200px: {
            transform: 'translate(0%, 20%)',
        },
    },

    imageRight: {
        ...img,
        right: '20%',
        min768px: {},
        min992px: {
            transform: 'translateX(-52%)',
        },
        min1200px: {
            transform: 'translateX(-20%)',
        },
        max768px: {
            display: 'none',
        },
    },

    polygonCenterBottom: {
        position: 'absolute',
        right: 'auto',
        left: 'auto',
        margin: 'auto',
        bottom: '14%',
        maxWidth: '6rem',
        marginRight: '10%',
    },
});
