import React from 'react';
import PropTypes from 'prop-types';
import * as componentStyles from './TopSliderMenu.style';
import { connect as connectFela } from 'react-fela';
import TopSliderMenuItem from './TopSliderMenuItem';
import { TopSliderItemShape } from '../TopSlider.items';

const TopSliderMenu = ({ styles, items, currentItem, onChangeItem }) => (
    <div className={styles.headerChanger}>
        {items.map(item => (
            <TopSliderMenuItem
                item={item}
                isActive={currentItem.headerIndex === item.headerIndex}
                onChangeHeader={onChangeItem}
                key={item.headerType}
            />
        ))}
    </div>
);

TopSliderMenu.propTypes = {
    items: PropTypes.arrayOf(TopSliderItemShape).isRequired,
    currentItem: TopSliderItemShape,
    onChangeItem: PropTypes.func.isRequired,
    styles: PropTypes.objectOf(PropTypes.string),
};

export default connectFela(componentStyles)(TopSliderMenu);
