import PropTypes from 'prop-types';
import { connect as connectFela, FelaComponent } from 'react-fela';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import * as componentStyles from './Section.style';
import React from 'react';
import Heading2 from '../../Heading2';
import Text from '../../Text';

const Section = ({
    styles,
    titleId,
    subTitleId,
    textId,
    children,
    type,
    extraContent,
    decoratorComponent,
    intl,
    overrideParagraphStyle,
    overrideTitleStyle,
}) => {
    const contentGenerated = () => (
        <>
            <span className={styles.subHeading}>{intl.formatMessage({ id: subTitleId })}</span>
            <Heading2 overrideStyles={[componentStyles.title, overrideTitleStyle]}>
                {intl.formatMessage({ id: titleId })}
            </Heading2>
            <Text overrideStyles={[componentStyles.paragraph, overrideParagraphStyle]}>
                {intl.formatHTMLMessage({ id: textId })}
            </Text>
            {extraContent && <div className={styles.extraContent}>{extraContent()}</div>}
        </>
    );
    return (
        <article className={styles.wrapper}>
            {decoratorComponent && decoratorComponent()}
            <FelaComponent style={componentStyles.content} type={type}>
                <aside className={styles.animationSite}>{children}</aside>
                <section className={styles.contentPart}>{contentGenerated()}</section>
            </FelaComponent>
        </article>
    );
};

Section.propTypes = {
    styles: PropTypes.shape({
        section: PropTypes.string,
        content: PropTypes.string,
        title: PropTypes.string,
        link: PropTypes.string,
        contentPart: PropTypes.string,
        titleLight: PropTypes.string,
        icon: PropTypes.string,
        wrapper: PropTypes.string,
        extraContent: PropTypes.string,
    }).isRequired,
    titleId: PropTypes.string.isRequired,
    subTitleId: PropTypes.string.isRequired,
    textId: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    type: PropTypes.oneOf(['left', 'right']).isRequired,
    animationOverrideStyle: PropTypes.func,
    overrideTitleStyle: PropTypes.func,
    overrideParagraphStyle: PropTypes.func,
    extraContent: PropTypes.func,
    overrideStyles: PropTypes.func,
    overrideContentPart: PropTypes.func,
};

Section.defaultProps = {
    animationOverrideStyle: () => {},
    overrideParagraphStyle: () => {},
    extraContent: () => {},
    decoratorComponent: () => {},
    overrideTitleStyle: () => {},
    overrideStyles: () => {},
    overrideContentPart: () => {},
};

export default compose(connectFela(componentStyles), injectIntl)(Section);
