import React from 'react';
import PropTypes from 'prop-types';
import { connect as connectFela } from 'react-fela';
import * as componentStyles from './BigFeedbackSection.style';
import Slick from 'react-slick';
import { injectIntl } from 'react-intl';
import useFeedbackSectionResize from './useFeedbackSectionResize';
import { compose } from 'recompose';

const BigFeedbackSection = ({ styles, items, intl }) => {
    const slickSettings = useFeedbackSectionResize();

    return (
        <div className={styles.bgWrapper}>
            <div className={styles.wrapper}>
                <Slick {...slickSettings}>
                    {items.map((item, i) => (
                        <div key={i} className={styles.innerWrapper}>
                            <p className={styles.text}>{intl.formatMessage({ id: item.textId })}</p>
                            <div className={styles.personWrapper}>
                                <img
                                    className={styles.personAvatar}
                                    src={item.person.avatar}
                                    alt=""
                                    role="presentation"
                                />
                                <span className={styles.personName}>{item.person.name}</span>
                                <span className={styles.personCompany}>
                                    {intl.formatMessage({ id: item.person.company })}
                                </span>
                            </div>
                        </div>
                    ))}
                </Slick>
            </div>
        </div>
    );
};

BigFeedbackSection.propTypes = {
    styles: PropTypes.shape({
        wrapper: PropTypes.string,
        innerWrapper: PropTypes.string,
        text: PropTypes.string,
    }),
    items: PropTypes.arrayOf(
        PropTypes.shape({
            textId: PropTypes.string,
            person: PropTypes.shape({
                name: PropTypes.string,
                company: PropTypes.string,
                avatar: PropTypes.string,
                personWrapper: PropTypes.string,
                personAvatar: PropTypes.string,
                personCompany: PropTypes.string,
                personName: PropTypes.string,
                bgWrapper: PropTypes.string,
            }),
        }),
    ).isRequired,
};

export default compose(connectFela(componentStyles), injectIntl)(BigFeedbackSection);
