export const headerChangerLink = ({ theme, isActive }) => ({
    color: '#D8D8D8',
    fontWeight: 500,
    fontSize: '1.5rem',
    lineHeight: 1.9,
    letterSpacing: 0,
    background: 'none',
    border: 'none',
    position: 'relative',
    padding: 0,
    marginRight: '3rem',
    float: 'left',
    opacity: 0.4,
    transition: 'opacity 0.5s ease',
    max768px: {
        marginRight: '1.25rem',
        fontSize: '1.25rem',
    },
    '&:after': {
        content: '""',
        height: 2,
        width: '1.9rem',
        visibility: isActive ? 'visible' : 'hidden',
        position: 'absolute',
        left: 0,
        bottom: 0,
        background: theme.colors.green,
    },
    '&:hover': {
        opacity: 1,
    },
    ...(isActive && {
        color: theme.colors.white,
        opacity: 1,
    }),
});
