import React, { useCallback, useEffect, useState } from 'react';
import useHeaderBackgrounds from './headerBackgrounds';
import BackgroundSection from '../../BackgroundSection';
import TopSliderMenu from './TopSliderMenu';
import { items } from '../TopSlider.items';
import useHeaderMockups from './headerMockups';
import { FelaComponent } from 'react-fela';
import * as componentStyles from './TopSlider.style';
import TopSliderContent from './TopSliderContent';
import { Row, Col } from 'antd/lib/grid';
import { connect as connectFela } from 'react-fela';
import Img from 'gatsby-image';

let lastSelectedIndex = 0; // keep slider on the same position during language re-render

const TopSlider = ({ styles }) => {
    const backgrounds = useHeaderBackgrounds();
    const mockups = useHeaderMockups();

    const [isContentVisible, setContentVisible] = useState(true);
    const [isBackgroundLoaded, setBackgroundLoaded] = useState(true);
    const [isMockupLoaded, setMockupLoaded] = useState(true);

    const [currentItem, setItem] = useState({
        ...items[lastSelectedIndex],
        background: backgrounds[items[lastSelectedIndex].headerType],
        mockup: mockups[items[lastSelectedIndex].headerType],
    });

    const handleChangeItem = useCallback(
        (index, type) => {
            if (lastSelectedIndex === index) {
                return;
            }
            lastSelectedIndex = index;
            setBackgroundLoaded(false);
            setMockupLoaded(false);
            setContentVisible(0);
            setTimeout(() => {
                setItem({
                    ...items[index],
                    background: backgrounds[type],
                    mockup: mockups[type],
                });
            }, 300); // first we need to fade out current image
        },
        [setItem, mockups, backgrounds, setBackgroundLoaded],
    );

    useEffect(() => {
        if (isBackgroundLoaded && isMockupLoaded) {
            const timeoutId = setTimeout(() => setContentVisible(1), 0);
            return () => clearTimeout(timeoutId);
        }
    }, [setContentVisible, isBackgroundLoaded, isMockupLoaded]);

    return (
        <FelaComponent style={componentStyles.animationStyle} isContentVisible={isContentVisible}>
            <BackgroundSection
                backgroundImage={currentItem.background}
                backgroundFullscreen={true}
                backgroundFadeIn
                onBackgroundLoad={() => setBackgroundLoaded(true)}
            >
                <Row className={styles.content} type="flex" align="bottom">
                    <Col xs={24} lg={14} xl={12} className={'Left-Col'}>
                        <TopSliderMenu items={items} currentItem={currentItem} onChangeItem={handleChangeItem} />
                        <TopSliderContent className={'TopSliderContent'} currentItem={currentItem} />
                    </Col>
                    <Col xs={24} lg={10} xl={12}>
                        <div className={styles.headerMockups}>
                            <Img fluid={currentItem.mockup} onLoad={() => setMockupLoaded(true)} fadeIn />
                        </div>
                    </Col>
                </Row>
            </BackgroundSection>
        </FelaComponent>
    );
};

export default connectFela(componentStyles)(TopSlider);
