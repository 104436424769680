import PropTypes from 'prop-types';
import { connect as connectFela } from 'react-fela';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import React from 'react';
import Section from '../../Section';

import * as componentStyle from './AboutAppSection.style';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import PolygonImage from '../../PolygonImage';
import { Parallax } from 'react-scroll-parallax/cjs';
import Link from '../../../../Link';
import { LinkType } from '../../../../Link/Link.style';

const AboutAppSection = ({ styles, intl }) => {
    const { topImage, middleImage, bottomImage } = useStaticQuery(
        graphql`
            query {
                topImage: file(relativePath: { eq: "hp/about/top.png" }) {
                    childImageSharp {
                        fixed(width: 402, height: 179) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                middleImage: file(relativePath: { eq: "hp/about/middle.png" }) {
                    childImageSharp {
                        fixed(width: 402, height: 180) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                bottomImage: file(relativePath: { eq: "hp/about/bottom.png" }) {
                    childImageSharp {
                        fixed(width: 337, height: 128) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `,
    );
    return (
        <div className={styles.sectionWrapper}>
            <Section
                type="right"
                titleId={`home.about.title`}
                subTitleId={`home.about.subTitle`}
                textId={`home.about.text`}
                extend={componentStyle.extend}
                extraContent={() => (
                    <Link styleType={'button'} to={'/features'} type={LinkType.GATSBY}>
                        {intl.formatMessage({ id: 'home.about.button.text' })}
                    </Link>
                )}
            >
                <PolygonImage className={styles.polygonTopRight} />
                <PolygonImage className={styles.polygonBottomLeft} />

                <Parallax className={styles.imageTop} y={[-36, 36]}>
                    <Img
                        fixed={topImage.childImageSharp.fixed}
                        alt={intl.formatMessage({ id: 'home.about.images.top.alt' })}
                        fadeIn
                    />
                </Parallax>
                <Parallax className={styles.imageMiddle} y={[-22, 22]}>
                    <Img
                        fixed={middleImage.childImageSharp.fixed}
                        alt={intl.formatMessage({ id: 'home.about.images.middle.alt' })}
                        fadeIn
                    />
                </Parallax>
                <Parallax className={styles.imageBottom} y={[-12, 12]}>
                    <Img
                        fixed={bottomImage.childImageSharp.fixed}
                        alt={intl.formatMessage({ id: 'home.about.images.bottom.alt' })}
                        fadeIn
                    />
                </Parallax>
            </Section>
        </div>
    );
};

AboutAppSection.propTypes = {
    styles: PropTypes.shape({
        imageTop: PropTypes.string,
        imageMiddle: PropTypes.string,
        imageBottom: PropTypes.string,
        polygonTopRight: PropTypes.string,
        polygonBottomLeft: PropTypes.string,
        sectionWrapper: PropTypes.string,
    }),
};

export default compose(connectFela(componentStyle.rules), injectIntl)(AboutAppSection);
