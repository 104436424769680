import PropTypes from 'prop-types';
import { connect as connectFela } from 'react-fela';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import React from 'react';

import * as componentStyle from './DesignSection.style';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import PolygonImage from '../../PolygonImage';
import { Parallax } from 'react-scroll-parallax/cjs';
import Section from '../../Section';

const DesignSection = ({ styles, intl }) => {
    const { left, right } = useStaticQuery(
        graphql`
            query {
                left: file(relativePath: { eq: "hp/phones/left.png" }) {
                    childImageSharp {
                        fixed(width: 409, height: 690) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                right: file(relativePath: { eq: "hp/phones/right.png" }) {
                    childImageSharp {
                        fixed(width: 408, height: 690) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `,
    );
    return (
        <Section
            type="left"
            titleId={`home.design.title`}
            subTitleId={`home.design.subTitle`}
            textId={`home.design.text`}
            extend={componentStyle.extend}
            decoratorComponent={() => (
                <>
                    <PolygonImage className={styles.polygonLeft} />
                    <PolygonImage className={styles.polygonTop} />
                    <PolygonImage className={styles.polygonRightBottom} />
                </>
            )}
        >
            <Parallax className={styles.imageLeft} y={[-32, 32]}>
                <Img
                    fixed={left.childImageSharp.fixed}
                    alt={intl.formatMessage({ id: 'home.design.images.left.alt' })}
                    fadeIn
                />
            </Parallax>
            <Parallax className={styles.imageRight} y={[-12, 12]}>
                <Img
                    fixed={right.childImageSharp.fixed}
                    alt={intl.formatMessage({ id: 'home.design.images.right.alt' })}
                    fadeIn
                />
            </Parallax>
        </Section>
    );
};

DesignSection.propTypes = {
    styles: PropTypes.shape({
        imageLeft: PropTypes.string,
        imageRight: PropTypes.string,
        polygonTop: PropTypes.string,
        polygonLeft: PropTypes.string,
        polygonRightBottom: PropTypes.string,
    }),
};

export default compose(connectFela(componentStyle.rules), injectIntl)(DesignSection);
