export const extend = ({ theme }) => ({
    animationSite: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row-reverse',
        max992px: {
            transform: 'scale(0.65)',
        },
        max480px: {
            transform: 'translateX(-15%) scale(0.65)',
        },
    },
    wrapper: {
        overflow: 'hidden',
    },
});

const img = {
    position: 'absolute',
    userSelect: 'none',
};

export const rules = ({ theme }) => ({
    wrapper: {
        position: 'relative',
        overflow: 'hidden',
    },

    imageLeft: {
        ...img,
        left: '10%',
        max768px: {
            left: 0,
        },
    },

    imageRight: {
        ...img,
        position: 'relative',
        float: 'right',
        max768px: {
            right: '20%',
        },
        max480px: {
            right: '-50%',
        },
    },

    polygonRightBottom: {
        position: 'absolute',
        right: '-1.875rem',
        maxWidth: '11.5rem',
        display: 'none',
        min992px: {
            display: 'block',
            bottom: 0,
        },
        min1490px: {
            bottom: '6.5rem',
        },
    },
});
