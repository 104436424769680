import PropTypes from 'prop-types';

export const TYPE_FESTIVAL = 'festival';
export const TYPE_CONFERENCE = 'conference';
export const TYPE_ACTION = 'action';

export const items = [
    {
        headerIndex: 0,
        headerType: TYPE_FESTIVAL,
        to: '/festival',
        downloads: 5000,
    },
    {
        headerIndex: 1,
        headerType: TYPE_CONFERENCE,
        to: '/conference',
        ratings: 4.6,
    },
    {
        headerIndex: 2,
        headerType: TYPE_ACTION,
        to: '/#contacts',
        downloads: 10000,
    },
];

export const TopSliderItemShape = PropTypes.shape({
    headerIndex: PropTypes.number.isRequired,
    headerType: PropTypes.oneOf([TYPE_FESTIVAL, TYPE_CONFERENCE, TYPE_ACTION]).isRequired,
    to: PropTypes.string.isRequired,
    ratings: PropTypes.number,
    downloads: PropTypes.number,
    background: PropTypes.object,
    mockup: PropTypes.object,
});
