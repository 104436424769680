export const wrapper = () => ({
    paddingTop: '14rem',
    maxWidth: '100rem',
    display: 'flex',
    position: 'relative',
    zIndex: 2,
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '14rem',
    '-webkit-overflow-scrolling': 'touch',
    max992px: {
        display: 'block',
        paddingTop: '0',
        overflow: 'hidden',
        paddingBottom: '0',
    },
    max768px: {
        paddingTop: '6rem',
        paddingBottom: 0,
        maxWidth: '100%',
    },
    overflow: 'hidden',
    width: '100%',
    margin: '0 auto',
    '& .Link-button': {
        marginTop: '2rem',
    },
});

export const content = ({ theme, type }) => ({
    display: 'flex',
    position: 'relative',
    width: '100%',
    max992px: {
        display: 'flex',
        flexFlow: 'column',
    },
    max768px: {
        overflow: 'hidden',
    },
    ...(type === 'right' && {
        flexDirection: 'row-reverse',
    }),
});

export const contentPart = ({ theme, type }) => ({
    width: '40%',
    alignSelf: 'center',
    paddingLeft: type === 'right' ? '5%' : '30px',
    paddingRight: type === 'right' ? '30px' : '5%',
    float: 'right',
    position: 'relative',
    max992px: {
        width: '100%',
        maxWidth: 'unset',
        textAlign: 'center',
        paddingLeft: '5%',
        paddingRight: '5%',
    },
});

export const animationSite = ({ theme }) => ({
    ...contentPart({ theme }),
    width: '60%',
    position: 'relative',
    padding: '0 34px',
    marginLeft: 'auto',
    marginRight: 'auto',
    float: 'left',
    paddingLeft: 0,
    display: 'block',
    alignSelf: 'unset',
    paddingRight: 0,
    max992px: {
        order: 2,
        marginBottom: '7.5rem',
        width: '100%',
        textAlign: 'center',
        display: 'block',
        paddingRight: '5%',
    },
});

export const title = ({ theme }) => ({
    fontWeight: 700,
    letterSpacing: -1,
    marginBottom: '1.25rem',
    maxWidth: '22rem',
    lineHeight: '3rem',
    max992px: {
        maxWidth: '100%',
    },
});

export const subHeading = ({ theme }) => ({
    textTransform: 'uppercase',
    display: 'block',
    padding: '0 1rem',
    marginTop: '4rem',
    marginBottom: '1rem',
    fontWeight: 700,
    fontFamily: theme.fontFamilyRoboto,
    color: theme.colors.black,
    opacity: 0.5,
    fontSize: '0.75rem',
    letterSpacing: '0.87px',
    lineHeight: '1rem',
});

export const link = () => ({
    color: `white`,
    textDecoration: `none`,
});

export const paragraph = () => ({
    maxWidth: '26rem',
    max992px: {
        maxWidth: '100%',
    },
});

export const extraContent = () => ({
    padding: '0 1rem',
});
