import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';

const useHeaderBackgrounds = () => {
    const data = useStaticQuery(
        graphql`
            query {
                festival: file(relativePath: { eq: "hp/img_hp_fest.jpg" }) {
                    childImageSharp {
                        fluid(quality: 95, maxWidth: 2560) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                conference: file(relativePath: { eq: "hp/img_hp_conf.jpg" }) {
                    childImageSharp {
                        fluid(quality: 95, maxWidth: 2560) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                action: file(relativePath: { eq: "hp/img_hp_action.jpg" }) {
                    childImageSharp {
                        fluid(quality: 95, maxWidth: 2560) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `,
    );
    return useMemo(
        () =>
            Object.keys(data).reduce((acc, key) => {
                acc[key] = data[key].childImageSharp.fluid;
                return acc;
            }, {}),
        [data],
    );
};

export default useHeaderBackgrounds;
