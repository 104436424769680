import PropTypes from 'prop-types';
import { connect as connectFela } from 'react-fela';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import React from 'react';
import Section from '../../Section';

import * as componentStyle from './ImportSection.style';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import PolygonImage from '../../PolygonImage';

import tableImg from '../../../../../images/hp/import/table.svg';
import { Parallax } from 'react-scroll-parallax/cjs';

const ImportSection = ({ styles, intl }) => {
    const { phone } = useStaticQuery(
        graphql`
            query {
                phone: file(relativePath: { eq: "hp/import/phone.png" }) {
                    childImageSharp {
                        fixed(width: 585, height: 605) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `,
    );

    return (
        <div className={styles.wrapper}>
            <Section
                type="right"
                titleId={`home.import.title`}
                subTitleId={`home.import.subTitle`}
                textId={`home.import.text`}
                extend={componentStyle.extend}
            >
                <Parallax y={[-8, 8]} className={styles.imageLeft}>
                    <img src={tableImg} alt={intl.formatMessage({ id: 'home.import.images.left.alt' })} />
                </Parallax>
                <Parallax className={styles.imageRight} y={[-16, 16]}>
                    <Img
                        fixed={phone.childImageSharp.fixed}
                        alt={intl.formatMessage({ id: 'home.import.images.right.alt' })}
                        fadeIn
                    />
                </Parallax>
            </Section>
            <PolygonImage className={styles.polygonRightBottom} />
        </div>
    );
};

ImportSection.propTypes = {
    styles: PropTypes.shape({
        imageLeft: PropTypes.string,
        imageRight: PropTypes.string,
        polygonRightBottom: PropTypes.string,
        wrapper: PropTypes.string,
    }).isRequired,
};

export default compose(connectFela(componentStyle.rules), injectIntl)(ImportSection);
