import React from 'react';
import withPageContext from '../pageContext';
import SEO from '../components/SEO';
import Main from '../components/Home/Main';
import HeaderScroller from '../components/HeaderScroller';
import Footer from '../components/Footer';
import ContactsSection from '../components/Event/ContactsSection/ContactsSection';
import BigFeedbackSection from '../components/BigFeedbacksSection/BigFeedbackSection';
import { LinkType } from '../components/Link/Link.style';

import avatarMighty from '../images/faces/img_face_mighty.png';
import avatarOpenHouse from '../images/faces/img_face_ohp.png';
import avatarJihlava from '../images/faces/img_face_mfdf.png';

const feedbacks = [
    {
        textId: 'home.feedbacks.mighty',
        person: {
            name: 'Marek Vohralík',
            company: 'home.feedbacks.mightyName',
            avatar: avatarMighty,
        },
    },
    {
        textId: 'home.feedbacks.openHouse',
        person: {
            name: 'Bohdana Rambousková',
            company: 'home.feedbacks.openHouseName',
            avatar: avatarOpenHouse,
        },
    },
    {
        textId: 'home.feedbacks.jihlava',
        person: {
            name: 'Šimon Bauer',
            company: 'home.feedbacks.jihlavaName',
            avatar: avatarJihlava,
        },
    },
];

const links = [
    { to: '/', labelId: 'home.menu.top', type: LinkType.GATSBY },
    { to: '/features', labelId: 'home.menu.features', type: LinkType.GATSBY },
    { to: '/festival', labelId: 'home.menu.festival', type: LinkType.GATSBY },
    { to: '/conference', labelId: 'home.menu.conference', type: LinkType.GATSBY },
    { to: '#contacts', labelId: 'home.menu.contacts', asButton: true, type: LinkType.NATIVE },
];

const IndexPage = ({ intl }) => (
    <>
        <SEO title="title.index" desc="description.index">
            <meta name="google-site-verification" content="OhGHz7YumyrJXfBtYR67zLDlHch_biLXtxoEuV8eboQ" />
        </SEO>
        <HeaderScroller links={links} />
        <Main />
        <BigFeedbackSection items={feedbacks} />
        <ContactsSection titleId="home.contacts.title" />
        <Footer />
    </>
);

export default withPageContext(IndexPage);
