import React, { useLayoutEffect, useState } from 'react';

import ArrowRight from './Arrows/ArrowRight';
import ArrowLeft from './Arrows/ArrowLeft';

const defaultSettings = {
    slidesToShow: 1,
    infinite: true,
    nextArrow: <ArrowRight />,
    prevArrow: <ArrowLeft />,
    centerMode: true,
    responsive: [
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                dots: true,
            },
        },
    ],
};

const TYPES = {
    BIG: '20%',
    SMALL: '0%',
};

const convertWidthToType = width => (width < 480 ? TYPES.SMALL : TYPES.BIG);

const useFeedbackSectionResize = () => {
    const [centerPadding, setCenterPadding] = useState(TYPES.BIG);

    useLayoutEffect(() => {
        const handleResize = () => setCenterPadding(convertWidthToType(window.innerWidth));
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return {
        ...defaultSettings,
        centerPadding: centerPadding,
    };
};

export default useFeedbackSectionResize;
