import { injectIntl } from 'react-intl';
import React from 'react';
import Section from '../../Section';

import * as componentStyle from './ReferencesSection.style';

import Link from '../../../../Link';
import LogoWall from './LogoWall/LogoWall';

const ReferencesSection = ({ intl }) => (
    <Section
        type="right"
        titleId={`home.references.title`}
        subTitleId={`home.references.subTitle`}
        textId={`home.references.text`}
        extend={componentStyle.extend}
        extraContent={() => (
            <Link styleType={'button'} to={'/#contacts'}>
                {intl.formatMessage({ id: 'home.references.button.text' })}
            </Link>
        )}
    >
        <LogoWall />
    </Section>
);

export default injectIntl(ReferencesSection);
