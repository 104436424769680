import React from 'react';

import TopSlider from './TopSlider/TopSlider';
import DesignSection from './Sections/Design/DesignSection';
import ImportSection from './Sections/Import/ImportSection';
import TeamSection from './Sections/Team/TeamSection';
import ReferencesSection from './Sections/References/ReferencesSection';
import { ParallaxProvider } from 'react-scroll-parallax';
import AboutAppSection from './Sections/AboutApp/AboutAppSection';

const Main = () => (
    <>
        <TopSlider />
        <main>
            <ParallaxProvider>
                <AboutAppSection />
                <DesignSection />
                <ImportSection />
                <TeamSection />
                <ReferencesSection />
            </ParallaxProvider>
        </main>
    </>
);

export default Main;
