export const extend = ({ theme }) => ({
    wrapper: {
        paddingTop: '7rem',
        paddingBottom: '7rem',
    },
    contentPart: {
        width: '50%',
        justifyContent: 'flex-start',
        '& .Heading2': {
            maxWidth: '44rem',
        },
        '& .Text': {
            maxWidth: '44rem',
        },
        '& span': {
            marginTop: 0,
        },
    },
    animationSite: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        width: '50%',
        flex: 0,
        marginRight: 'unset',
        max1200px: {
            transform: 'scale(0.85)',
        },
        max992px: {
            transform: 'translateY(-10%) scale(0.75)',
        },
    },
});

const img = {
    userSelect: 'none',
    position: 'relative',
};

export const rules = ({ theme }) => ({
    sectionWrapper: {
        backgroundColor: theme.colors.greyLight,
        max1200px: {
            paddingTop: '4rem',
        },
    },
    imageTop: {
        ...img,
        zIndex: 1,
        top: '10rem',
        right: '15%',
        min992px: {
            top: '5rem',
            right: '30%',
        },
        min1200px: {
            right: '50%',
        },
    },
    imageMiddle: {
        ...img,
        zIndex: 2,
        top: '7rem',
        min992px: {
            top: '2.5rem',
        },
        min1200px: {
            right: 0,
        },
    },
    imageBottom: {
        ...img,
        zIndex: 3,
        top: '5rem',
        min992px: {
            top: 'unset',
        },
        min1200px: {
            bottom: '8%',
            right: '30%',
        },
    },
    polygonTopRight: {
        position: 'absolute',
        maxWidth: '13.5rem',
        top: '4%',
        right: '27.5%',
    },
    polygonBottomLeft: {
        position: 'absolute',
        maxWidth: '12rem',
        bottom: '0',
        left: '-35%',
    },
});
