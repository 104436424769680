import React from 'react';
import PropTypes from 'prop-types';
import * as componentStyles from './LogoWall.style.js';

import { connect as connectFela } from 'react-fela';

import deloitte from '../../../../../../images/hp/references/partners/deloitte.svg';
import majales from '../../../../../../images/hp/references/partners/majales.svg';
import mighty from '../../../../../../images/hp/references/partners/mighty.svg';
import iinfo from '../../../../../../images/hp/references/partners/iinfo.svg';
import lfsuh from '../../../../../../images/hp/references/partners/lfsuh.svg';
import openhouse from '../../../../../../images/hp/references/partners/openhouse.svg';
import jihlava from '../../../../../../images/hp/references/partners/jihlava.svg';
import weearit from '../../../../../../images/hp/references/partners/wearit.svg';
import be2can from '../../../../../../images/hp/references/partners/be2can.svg';
import devcon from '../../../../../../images/hp/references/partners/devcon.svg';

const logosElements = [
    {
        name: 'deloitte',
        src: deloitte,
    },
    {
        name: 'majales',
        src: majales,
    },
    {
        name: 'Mighty Sounds',
        src: mighty,
    },
    {
        name: 'Letní filmová škola Uherské Hradiště',
        src: lfsuh,
    },
    {
        name: 'Internet info',
        src: iinfo,
    },
    {
        name: 'Ji.hlava 20',
        src: jihlava,
    },
    {
        name: 'Wear it Festial - Berlin',
        src: weearit,
    },
    {
        name: 'Open House Praha',
        src: openhouse,
    },
    {
        name: 'Be2Can',
        src: be2can,
    },
].map(logo => <img key={logo.name} src={logo.src} alt={logo.name} />);

const LogoWall = ({ styles }) => (
    <div className={styles.wrapper}>
        <div className={styles.column}>{logosElements.filter((_, i) => i % 3 === 0)}</div>
        <div className={styles.middleColumn}>
            {logosElements.filter((_, i) => i % 3 === 1)}
            <img src={devcon} alt="Devcon" />
        </div>
        <div className={styles.column}>{logosElements.filter((_, i) => i % 3 === 2)}</div>
    </div>
);

LogoWall.propTypes = {
    styles: PropTypes.shape({
        wrapper: PropTypes.string,
        column: PropTypes.string,
        middleColumn: PropTypes.string,
        img: PropTypes.string,
    }),
};

export default connectFela(componentStyles)(LogoWall);
